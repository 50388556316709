@import url(https://fonts.googleapis.com/css2?family=Roboto:wght@100;400;700;900&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Oswald:wght@200;400;700&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Square+Peg&display=swap);
*, *::before, *::after {
  box-sizing: border-box;
}

body {
  margin: 0;
  font-family: 'Roboto', Arial;
  background-color: transparent!important;
}
a{ 
  font-family: 'Roboto';
  font-weight: normal;
  font-size:13px
}
.containheight{
  box-shadow:0 2px 41px 10px #6b82af1a;
  height:620px
}
.options-btn{
  background-color: rgb(235, 239, 241);
  padding:15px;
  border-radius: 5px;
  text-decoration:none
}
.contentpreview{

    position: relative;
    width: 582px;
    height: 385px;
    overflow: hidden;

}

.filtre_options a{
  padding:10px;
  margin:5px;
  text-decoration:none;
  background-color:rgb(235, 239, 241);
  border-radius: 10px
}
.block-image{
  height: 50vh;
}
.main-image {
  grid-area: image;
  width: 100%;
  height: 50vh;
  background-position: top center;
  background-size: contain;
  background-repeat: no-repeat;
}
#cp-image,#aperçu{
  position:relative
}
.filter-initial{
  position:absolute;
}
.border{
  position:absolute;
  top:0;
  left:5px
} 
.realimage{
  position:relative!important;
}

.sidebar-item {
  cursor: pointer;
  border: none;
  outline: none;
  padding: 1rem;
  position: relative;
  transition: background-color 150ms;
}

.sidebar-item:hover, .sidebar-item:focus {
  background-color: hsl(0, 0%, 100%);
}

.sidebar-item.active {
  background-color: hsl(265, 100%, 70%);
}

.sidebar-item::after {
  content: '';
  position: absolute;
  width: 80%;
  left: 10%;
  bottom: 0;
  height: 1px;
  background-color: hsl(265, 100%, 46%);
}

.option-block{
  padding:5px;
  text-align: left!important;
}

.slider {
  width: 70%;
  cursor: pointer;
}

.btn-custom-download{
  max-height:50px
}

#real{
  position:absolute;
  left:2050px
}

.flip-right {
  transform: scaleX(-1);
}

.flip-left {
  transform: scaleX(1);
}
.flip-top {
  transform: scaleY(-1);
}
.flip-bottom {
  transform: scaleY(1);
}
